import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import $ from "jquery";
import { Link } from "react-router-dom";
import { getParentOrganisation } from "../../actions/adminAction";
import Cookies from "universal-cookie";
import { FaRegCalendar } from "react-icons/fa";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
const cookies = new Cookies();

const Sidebar = ({ type }) => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const [isGroup, setIsGroup] = useState(false);
  const [permissions, setPermissions] = useState(
    localRole && localRole.permissions ? localRole.permissions : "All"
  );
  const org_id = cookies.get("org_id");

  useEffect(() => {
    if (window.innerWidth <= 768) {
      document.body.classList.remove("sidebar-enable");
    }

    $("#side-menu a").each(function () {
      if (this.href === window.location.href) {
        $(this).addClass("active");
        $(this).parent().addClass("mm-active");
        $(this).parent().parent().addClass("mm-show");
        $(this).parent().parent().prev().addClass("active");
        $(this).parent().parent().parent().addClass("mm-active");
        $(this).parent().parent().parent().parent().addClass("mm-show");
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass("mm-active");
      }
    });

    $(".nav-second-level").removeClass("mm-show").addClass("mm-collapse");

    if (type === "profiles") {
      $("#profilesGrp").removeClass("mm-collapse").addClass("mm-show");
    }

    loadOrganisationData();
  }, [type]);

  const loadOrganisationData = async () => {
    try {
      const data = await getParentOrganisation(org_id);
      if (data.error) {
        console.error(data.error);
      } else {
        const isGroup =
          data.org && data.org.length > 0 ? data.org[0].isGroup : false;
        setIsGroup(isGroup);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (e) => {
    if ($(e.target).next().hasClass("mm-collapse")) {
      $(".nav-second-level").removeClass("mm-show").addClass("mm-collapse");
      $(e.target).next().removeClass("mm-collapse").addClass("mm-show");
    } else {
      $(".nav-second-level").removeClass("mm-show").addClass("mm-collapse");
    }
  };

  return (
    <div className="left-side-menu">
      <style>
        {`
          .left-side-menu .metismenu a {
            color: inherit; 
            text-decoration: none; 
          }

          .left-side-menu .metismenu a:hover {
            color: #0BAAE5 !important; 
          }

          .left-side-menu .metismenu li.mm-active > a {
            color: #0BAAE5 !important; 
          }
        `}
      </style>
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgDashboard" && item.isView
              )) && (
              <li>
                <Link to="/organisation/dashboard">
                  <i className="fe-airplay"></i>{" "}
                  <span>{STRING_CONSTANTS.DASHBOARD}</span>
                </Link>
              </li>
            )}

            {!(
              permissions !== "All" &&
              permissions.some(
                (item) => item.keyword === "orgProfileCustomers" && !item.isView
              ) &&
              permissions.some(
                (item) =>
                  item.keyword === "orgProfileVolunteers" && !item.isView
              )
            ) && (
              <li>
                <a className="groupMenu" href="#" onClick={handleClick}>
                  <i className="fas fa-users"></i> {STRING_CONSTANTS.PROFILES}
                  <span className="menu-arrow"></span>
                </a>
                <ul
                  id="profilesGrp"
                  className="nav-second-level"
                  aria-expanded="false"
                >
                  {(permissions === "All" ||
                    permissions.some(
                      (item) =>
                        item.keyword === "orgProfileCustomers" && item.isView
                    )) && (
                    <li>
                      <li>
                        <Link to="/organisation/customer">
                          {STRING_CONSTANTS.CUSTOMERS}
                        </Link>
                      </li>
                    </li>
                  )}

                  {(permissions === "All" ||
                    permissions.some(
                      (item) =>
                        item.keyword === "orgProfileVolunteers" && item.isView
                    )) && (
                    <li>
                      <li>
                        <Link to="/organisation/members">
                          {STRING_CONSTANTS.VOLUNTEERS}
                        </Link>
                      </li>
                    </li>
                  )}

                  {(permissions === "All" ||
                    permissions.some(
                      (item) =>
                        item.keyword === "orgProfileEmployee" && item.isView
                    )) && (
                    <li>
                      <li>
                        <Link to="/organisation/employees">
                          {STRING_CONSTANTS.EMPLOYEES}
                        </Link>
                      </li>
                    </li>
                  )}
                  {isGroup && (
                    <li>
                      <Link to="/organisation/manageOrganisation">
                        {STRING_CONSTANTS.MANAGE_ORG}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgSurvey" && item.isView
              )) && (
              <li>
                <Link to="/organisation/survey">
                  <i className="fe-file-text"></i>{" "}
                  <span>{STRING_CONSTANTS.SURVEY}</span>
                </Link>
              </li>
            )}
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProducts" && item.isView
              )) && (
              <li>
                <Link to="/organisation/products">
                  <i className="fe-shopping-cart" aria-hidden="true"></i>
                  <span>{STRING_CONSTANTS.PRODUCTS}</span>
                </Link>
              </li>
            )}
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProducts" && item.isView
              )) && (
              <li>
                <Link to="/organisation/chat">
                  <ChatBubbleIcon style={{ fontSize: 17, marginRight: 5 }} />
                  <span>{STRING_CONSTANTS.CHAT}</span>
                </Link>
              </li>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgMarketPlace" && item.isView
              )) && (
              <li>
                <Link to="/organisation/marketplace">
                  <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>
                  <span>{STRING_CONSTANTS.MARKETPLACE}</span>
                </Link>
              </li>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgLeads" && item.isView
              )) && (
              <li>
                <Link to="/organisation/leads">
                  <i className="fa fa-tasks"></i>{" "}
                  <span>{STRING_CONSTANTS.YOUR_LEADS}</span>
                </Link>
              </li>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) =>
                  item.keyword === "orgLeadsHarvestPlanner" && item.isView
              )) && (
              <li>
                <Link to="/organisation/harvestPlanner">
                  <FaRegCalendar style={{ marginLeft: "5px" }} />
                  <span style={{ paddingLeft: "11px" }}>Harvest Planner</span>
                </Link>
              </li>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgCustomerSupport" && item.isView
              )) && (
              <li>
                <Link to="/organisation/subscription">
                  <i className="fa fa-book fa-fw"></i>{" "}
                  <span>{STRING_CONSTANTS.SUBSCRIPTION}</span>
                </Link>
              </li>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgGallery" && item.isView
              )) && (
              <li>
                <Link to="/organisation/gallery">
                  <i className="fa fa-camera-retro" aria-hidden="true"></i>{" "}
                  <span>{STRING_CONSTANTS.GALLERY}</span>
                </Link>
              </li>
            )}
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgCustomerSupport" && item.isView
              )) && (
              <li>
                <Link to="/organisation/customerSupport">
                  <i className="fa fa-headphones" aria-hidden="true"></i>
                  <span>{STRING_CONSTANTS.CUSTOMER_SUPPORT}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="clearfix"></div>
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
